import React from 'react';
import {
  Box,
  Button,
  Stack,
  Heading,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

export default function Hero({ categories, categoryName }: {
  categories: object[],
  categoryName?: string,
}) {
  return (
    <Box
      px={8}
      pt={24}
      mx="auto"
    >
      <Box
        w={{
          base: 'full',
          md: 11 / 12,
          xl: 9 / 12,
        }}
        mx="auto"
        textAlign="center"
      >
        <Heading
          as="h1"
          mb={6}
          fontSize={{
            base: '4xl',
            md: '6xl',
          }}
          fontWeight="bold"
          lineHeight="none"
          letterSpacing={{
            base: 'normal',
            md: 'tight',
          }}
          color="gray.900"
          _dark={{
            color: 'gray.100',
          }}
        >
          Blogi
          {categoryName ? ` - ${categoryName}` : ''}
        </Heading>
      </Box>
      <Stack
        direction={{
          base: 'column',
          md: 'row',
        }}
        mb={{
          base: 4,
          md: 8,
        }}
        spacing={2}
        justifyContent="center"
      >
        <Button
          as={GatsbyLink}
          to="/blogi"
          colorScheme="accesibleButton"
          _hover={
            {
              backgroundColor: '#00916a',
              color: 'white',
            }
          }
          variant={categoryName ? 'outline' : 'solid'}
        >
          Kaikki
        </Button>
        {categories.map((category: any) => (
          <Button
            as={GatsbyLink}
            to={`/blogi/${category.slug}`}
            colorScheme="accesibleButton"
            _hover={
              {
                backgroundColor: '#00916a',
                color: 'white',
              }
            }
            variant={categoryName === category.name ? 'solid' : 'outline'}
          >
            {category.name}
          </Button>
        ))}
      </Stack>
    </Box>
  );
}

Hero.defaultProps = {
  categoryName: undefined,
};
