import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  HStack,
  Tag,
  SpaceProps,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

function BlogTags(props: IBlogTags) {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => (
        <Tag size="md" variant="solid" colorScheme="green" key={tag}>
          {tag}
        </Tag>
      ))}
    </HStack>
  );
}

BlogTags.defaultProps = {
  marginTop: 0,
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export function BlogAuthor(props: BlogAuthorProps) {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString('fi')}</Text>
    </HStack>
  );
}

interface BlogPostProps {
  slug: string;
  title: string;
  imageUrl: string;
  category: string;
  authors: string[];
  date: Date;
}

function BlogPost(props: BlogPostProps) {
  return (
    <Box w="100%">
      <Box borderRadius="lg" overflow="hidden">
        <Link
          as={GatsbyLink}
          to={`/${props.slug}`}
          textDecoration="none"
          _hover={{ textDecoration: 'none' }}
        >
          <Image
            transform="scale(1.0)"
            src={`${props.imageUrl}?fm=webp`}
            alt="some text"
            objectFit="contain"
            width="100%"
            transition="0.3s ease-in-out"
            _hover={{
              transform: 'scale(1.05)',
            }}
            sx={{
              aspectRatio: '3 / 2',
            }}
          />
        </Link>
      </Box>
      <BlogTags tags={[props.category]} marginTop="3" />
      <Heading fontSize="xl" marginTop="2">
        <Link
          as={GatsbyLink}
          to={`/${props.slug}`}
          textDecoration="none"
          _hover={{ textDecoration: 'none' }}
        >
          {props.title}
        </Link>
      </Heading>
      <BlogAuthor
        name={[props.authors.slice(0, -1).join(', '), props.authors.slice(-1)[0]].join(props.authors.length < 2 ? '' : ' ja ')}
        date={props.date}
      />
    </Box>
  );
}

export default BlogPost;
