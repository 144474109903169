import * as React from 'react';
import {
  Box,
  Flex, SimpleGrid,
} from '@chakra-ui/react';
import AppTemplate from '../components/AppTemplate';
import Hero from '../components/BlogSections/ListPostsHero';
import BlogPost from '../components/BlogSections/BlogPost';

// markup
function BlogList({ pageContext: data }: any) {
  return (
    <AppTemplate
      navigation={data.navigation}
      SEO={{
        title: 'Ritta - Blogi',
        description: 'Rittan blogista löytyy kaikenlaista tietoa liittyen Rittaan ja opetusteknologiaan.',
        pathname: '/blogi',
      }}
    >
      <Hero categories={data.categories} categoryName={data.categoryName} />
      <Flex
        direction={{
          base: 'column',
          sm: 'row',
        }}
        mb={{
          base: 4,
          md: 8,
        }}
        justifyContent="center"
      >
        <SimpleGrid
          columns={{
            base: 1,
            md: 3,
          }}
          gap={6}
          mx={{
            base: 5,
            md: 50,
          }}
          width={{
            base: '90%',
            md: '75vw',
          }}
          spacingY={10}
        >
          {(data.posts || [])
            .filter(
              (post: any) => (data.categoryName ? post.category.name === data.categoryName : true),
            )
            .map((post: any) => (
              <BlogPost
                title={post.title}
                category={post.category.name}
                date={new Date(post.createdAt)}
                slug={post.slug}
                authors={post.author.map((author: any) => author.name)}
                imageUrl={post.thumbnail.file.url}
              />
            ))}
        </SimpleGrid>
      </Flex>
      <Box
        sx={{
          background: 'linear-gradient(0deg, var(--chakra-colors-gray-50) 0%, #fff 100%)',
          height: '45px',
          width: '100%',
        }}
      />
    </AppTemplate>
  );
}

export default BlogList;
